import React from "react";

import {Helmet} from "react-helmet";
// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";

// core components
import MainNavbar from "components/Navbars/MainNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import VH2022Page from "./VH2022Page.js";
import VR2023Page from "./VR2023Page.js";
import NJ2025Page from "./NJ2025Page.js";

function LittersPage() {
  let { path, url } = useRouteMatch();
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
      <>
      <Helmet>
        <meta name="description" content="Endgame Sports Dogs produces health tested border collies and sport mixes for competing in dog sports such as agility, flyball, dock diving, herding, and disc. Located in Raleigh, NC." />
      </Helmet>
      <Switch>
        <Route exact path={path}>
          <MainNavbar />
          <div className="wrapper">
            <LandingPageHeader bg="header4" />
            <div className="section section-about-us">
              <Container>
                <div className="separator separator-primary">
                  <h2 className="title">Information on litters</h2>
                  <p>Our puppies are raised with information from the Puppy Culture and Avidog programs and will be exposed to ENS and ESI. A facebook group is created for each litter so you can follow along with their development. Puppies have BAER (hearing) and CAER (eye) exams before going to their home and come with a health guarantee for genetic conditions. Puppy prices are usually $2000 but may range based on costs associated with a particular breeding and number of puppies in the litter.</p> 
                  <p>Litters are planned based on current competition schedules and are subject to change. I maintain an interested list for each litter but do not take deposits until it is confirmed you will be getting a puppy. I will choose owners based on best match, not who joined the waiting list first. Puppy picks will be decided between myself and the purchaser. I will not allow picks to be solely based off appearance. All puppies are sold as pets (non-breeding) but I am open to discussing giving rights if the dog demonstrates breeding worthy qualities, is fully health tested, and breedings are approved by me. Co-ownerships are occasionally available and offer a free or reduced price puppy depending on the contract decided between myself and the co-owner. </p>
                  <p>I am always happy to help out junior handlers! If you're a junior interested in a dog that may need assistance, feel free to reach out.</p>
                  
                  <h2 className="title">Upcoming Litters</h2>
                  <div className="litter">
                    <p>Venus will potentially be bred in 2025 to a small dog, likely a terrier. She will have her final litter in late 2025/2026 with a border collie pending the health of her first purebred litter.</p>
                    <p>Nimona will be bred early 2025 (expected in heat March - May) to a sport mix for medium sized dogs. Chibi will be bred late 2025 for a small/medium size litter.</p>
                  </div>
                  <Row>
                    
                  </Row> 
                  <Row>
                    <Col md="4"></Col>
                    <Col md="4" className="text-center">
                      <a href="/litters/nimona-jerome-2025">
                        <h4>Nimona X Jerome (2025)</h4>
                        <img
                          alt=""
                          className="img-raised"
                          src={require("../../assets/img/dogs/litters/nj2025/announcement2.jpg")}
                        />
                      </a> 
                    </Col>
                  </Row>
                  <h2 className="title pt-5">Previous Litters</h2>
                  <Row>
                    <Col md="2"></Col>
                    <Col md="4" className="text-center">
                      <a href="/litters/venus-hopsing-2022">
                        <h4>Venus X HopSing (2022)</h4>
                        <img
                          alt=""
                          className="img-raised"
                          src={require("../../assets/img/dogs/litters/vh2022/announcement.jpg")}
                        />
                      </a> 
                    </Col>
                    <Col md="4" className="text-center">
                      <a href="/litters/venus-rip-2023">
                        <h4>Venus X Rip (2023)</h4>
                        <img
                          alt=""
                          className="img-raised"
                          src={require("../../assets/img/dogs/litters/vr2023/announcement.jpg")}
                        />
                      </a> 
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
            <DefaultFooter />
          </div>
        </Route>
        <Route path={`${path}/venus-hopsing-2022`}>
          <VH2022Page />
        </Route>
        <Route path={`${path}/venus-rip-2023`}>
          <VR2023Page />
        </Route>
        <Route path={`${path}/nimona-jerome-2025`}>
          <NJ2025Page />
        </Route>
      </Switch>
    </>
  );
}

export default LittersPage;
