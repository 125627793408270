import React from "react";

import {Helmet} from "react-helmet";
// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  ModalBody, Modal
} from "reactstrap";

// core components
import MainNavbar from "components/Navbars/MainNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import PuppyCarousel from "components/Other/PuppyCarousel.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

function NJ2025Page() {
  const [modal1, setModal1] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
      <>
        <MainNavbar />
        <div className="wrapper">
          <LandingPageHeader bg="nj2025" />
        <div className="back-nav">
          <a href="../litters"><i className="now-ui-icons arrows-1_minimal-left"></i> Back to Litters</a>
        </div>
          <div className="section section-about-us">
            <Container>
              <div className="separator separator-primary">
                 <Row>
                  <Col md="5">
                    <a onClick={() => setModal1(true)}>
                      <img
                          alt="..."
                          className="img-fluid img-raised"
                          src={require("../../assets/img/dogs/litters/nj2025/announcement2.jpg")}
                          onClick={() => setModal1(false)}
                        >
                      </img>
                    </a>
                    <Modal isOpen={modal1} toggle={() => setModal1(false)} style={{
                      maxWidth: "900px"
                    }}>
                      <div className="modal-header justify-content-center">
                        <button
                          className="close"
                          type="button"
                          onClick={() => setModal1(false)}
                        >
                          <i className="now-ui-icons ui-1_simple-remove"></i>
                        </button>
                        <h4 className="title title-up"></h4>
                      </div>
                      <ModalBody>
                        <img
                          alt="..."
                          src={require("../../assets/img/dogs/litters/nj2025/announcement2.jpg")}
                        ></img>
                      </ModalBody>
                    </Modal>
                  </Col>
                  <Col md="7">
                    <h2 className="title pt-0">Jerome x Nimona 2025</h2>
                    <p>Jerome is a well rounded, multi mix sport dog from Tactical Image in Canada. He was third in finals at the UKI invitational in 2024 and has a flyball pb of 3.6. His father has been on many world teams and has won at multiple national events. His mother is in the lap dogs hall of fame in Dock Dogs and has a pb of 28'2. She also played flyball and has a pb of 3.62. Jerome has siblings and relatives are also doing well in a variety of sports including setting sub 4 times in flyball, making podiums in agility, and holding records in disc. He is neutral with strangers, well behaved in public, and performs in trick shows for large crowds. Both him and Nimona have a great off switch inside the house and are very high drive when working.</p>
                    <p>I'm expecting this breeding to produce high drive puppies who will thrive in experienced sport homes. I will consider intermediate level handlers who are looking for a competitive dog. Jerome competes in agility, flyball, dock diving, disc and obedience. Nimona competes in flyball, agility, disc, dock diving, and barn hunt. Preference will be for agility and/or flyball focused homes.</p>
                    <p>Jerome and Nimona are clear of all testable dieseases that would effect the breeds that make up their mixes. Nimona has been additionally cleared for CDDY by UC Davis laboratory. They both have been cleared of hip dysplasia, luxating patellas, and eye issues. Nimona has also been cleared of shoulder OCD. Jerome has been cleared of elbow dysplasia and cardiac issues. He is 16.6" tall and 22lbs. Nimona is 15.75" tall and 20lbs. The estimated breed breakdown of the puppies is: 43% border collie, 33% jack russell, 14% whippet, 7% staffie, 3% border terrier. There is a possibility for smooth or rough coats. I expect puppies to range in size from 15"-17" but multigenerational mixes may be a little smaller or taller. BAER, CAER, and Embark testing will be done before the puppies go home.</p>
                    <p>For more information about Nimona, view her individual page. For more information on Jerome, contact Morgan Jarvis or reach out to me if you would like to learn about this cross.</p> 
                  </Col>
                </Row>
                <Row className="pt-5 pb-5">
                  <Col md="3">
                    <a className="dogs-link">
                      <img
                      alt="..."
                      className="img-fluid img-raised"
                      src={require("../../assets/img/dogs/profile-jerome.jpg")}
                      ></img>
                      <div className="dogs-name"><span>Jerome</span></div>
                    </a>
                    <a href="/dogs/nimona" className="dogs-link">
                      <img
                      alt="..."
                      className="img-fluid pt-5"
                      src={require("../../assets/img/dogs/profile-nimona.jpg")}
                    ></img>
                      <div className="dogs-name"><span>Nimona</span></div>
                    </a>
                  </Col>
                  <Col md="9" className="align-middle">
                    <img
                        alt="..."
                        className="img-fluid pt-5"
                        src={require("../../assets/img/dogs/litters/nj2025/pedigree.jpg")}
                        onClick={() => setModal2(false)}
                      >
                    </img>
                  </Col>
                </Row>  
              </div>
              <h2 className="title text-center pt-5">Stacks</h2>
              <div className="team pb-5">
                <Row>
                  <Col md="6">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={require("../../assets/img/dogs/litters/nj2025/jerome-stack.jpg")}
                    />
                  </Col>
                  <Col md="6">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={require("../../assets/img/dogs/nimona/stack3.jpg")}
                    />
                  </Col>
                </Row>
              </div> 
            </Container>
            <Container>
              <h2 className="title text-center pt-5">Videos of Nimona</h2>
              <div className="team">
                <Row className="video-row">
                  <Col md="6">
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/6uW-raxdnjw?si=3h72Isk9tvIWTTsG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
                  </Col>
                  <Col md="6">
                    <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fendgamesportdogs%2Fvideos%2F1089030808818866%2F&show_text=false&width=560&t=0" width="100%" height="100%" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share; fullscreen"></iframe>
                  </Col>
                </Row>
              </div>  

              <h3 className="title text-center">Nimona's Littermates</h3>
              <div className="team pb-5">
                <Row className="video-row">
                  <Col md="6" className="text-center">
                    <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fendgamesportdogs%2Fvideos%2F751541700434377%2F&show_text=false&width=560&t=0" width="100%" height="100%" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share; fullscreen"></iframe>
                    <p>Chibiusa</p>
                  </Col>
                  <Col md="6" className="text-center">
                    <iframe src="https://www.facebook.com/plugins/video.php?height=323&href=https%3A%2F%2Fwww.facebook.com%2Fendgamesportdogs%2Fvideos%2F7537694036243530%2F&show_text=false&width=560&t=0" width="100%" height="100%" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share; fullscreen"></iframe>
                    <p>Cinco</p>
                  </Col>
                </Row>
              </div>  

              <h2 className="title text-center pt-5">Videos of Jerome</h2>
              <div className="team">
                <Row className="video-row">
                  <Col md="6">
                    <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fmorgan.jarvis.18%2Fvideos%2F1967448656964732%2F&show_text=false&width=560&t=0" width="100%" height="100%" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share; fullscreen"></iframe>
                  </Col>
                  <Col md="6">
                    <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fmorgan.jarvis.18%2Fvideos%2F435274972318100%2F&show_text=false&width=560&t=0" width="100%" height="100%" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share; fullscreen"></iframe>
                  </Col>
                </Row>
              </div>  

              <h3 className="title text-center">Jerome's Relatives</h3>
              <div className="team">
                <Row className="video-row">
                  <Col md="6" className="text-center">
                    <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fmorgan.jarvis.18%2Fvideos%2F279431501648046%2F&show_text=false&width=560&t=0" width="100%" height="100%" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share; fullscreen"></iframe>
                    <p>Tension (Repeat breeding Qwik x Image)</p>
                  </Col>
                  <Col md="6" className="text-center">
                    <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fmorgan.jarvis.18%2Fvideos%2F814918330480365%2F&show_text=false&width=560&t=0" width="100%" height="100%" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share; fullscreen"></iframe>
                    <p>Qwik (Jerome's father)</p>
                  </Col>
                </Row>
              </div>  
              <div className="team text-center pt-5">
                <p>More videos of siblings/parents/relatives doing sports are available through messages.</p> 
              </div>  
            </Container>
          </div>
          <DefaultFooter />
        </div>
      </>
  );
}

export default NJ2025Page;
