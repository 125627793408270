import React from "react";

import {Helmet} from "react-helmet";
// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  ModalBody, Modal
} from "reactstrap";

// core components
import MainNavbar from "components/Navbars/MainNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import PuppyCarousel from "components/Other/PuppyCarousel.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import DogGallery from "../dogs/DogGallery";

function VH2022Page() {
  const [modal1, setModal1] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  const PlutoImages = [
    { src: require("../../assets/img/dogs/litters/vh2022/pluto5.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/pluto4.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/pluto3.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/pluto2.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/pluto1.jpg")}
  ];

  const MercuryImages = [
    { src: require("../../assets/img/dogs/litters/vh2022/mercury5.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/mercury4.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/mercury3.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/mercury2.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/mercury1.jpg")}
  ];

  const SaturnImages = [
    { src: require("../../assets/img/dogs/litters/vh2022/saturn5.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/saturn4.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/saturn3.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/saturn2.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/saturn1.jpg")}
  ];

  const JupiterImages = [
    { src: require("../../assets/img/dogs/litters/vh2022/jupiter5.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/jupiter4.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/jupiter3.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/jupiter2.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/jupiter1.jpg")}
  ];

  const NeptuneImages = [
    { src: require("../../assets/img/dogs/litters/vh2022/neptune5.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/neptune4.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/neptune3.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/neptune2.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/neptune1.jpg")}
  ];

  const MarsImages = [
    { src: require("../../assets/img/dogs/litters/vh2022/mars5.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/mars4.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/mars3.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/mars2.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/mars1.jpg")}
  ];

  const MoonImages = [
    { src: require("../../assets/img/dogs/litters/vh2022/moon5.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/moon4.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/moon3.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/moon2.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/moon1.jpg")}
  ];

  const ChibiusaImages = [
    { src: require("../../assets/img/dogs/litters/vh2022/chibiusa5.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/chibiusa4.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/chibiusa3.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/chibiusa2.jpg")},
    { src: require("../../assets/img/dogs/litters/vh2022/chibiusa1.jpg")}
  ];

  const FlyballImages = [
  ];

  const images = [
    require("../../assets/img/dogs/litters/vh2022/reg1.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg2.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg3.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg4.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg5.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg6.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg7.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg8.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg9.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg10.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg11.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg12.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg13.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg14.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg15.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg16.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg17.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg18.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg19.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg20.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg21.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg22.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg23.jpg"),
    require("../../assets/img/dogs/litters/vh2022/reg24.jpg")
  ];

  return (
      <>
        <MainNavbar />
        <div className="wrapper">
          <LandingPageHeader bg="vh2022" />
        <div className="back-nav">
          <a href="../litters"><i className="now-ui-icons arrows-1_minimal-left"></i> Back to Litters</a>
        </div>
          <div className="section section-about-us">
            <Container>
              <div className="separator separator-primary">
                 <Row>
                  <Col md="5">
                    <a onClick={() => setModal1(true)}>
                      <img
                          alt="..."
                          className="img-fluid img-raised"
                          src={require("../../assets/img/dogs/litters/vh2022/announcement.jpg")}
                          onClick={() => setModal1(false)}
                        >
                      </img>
                    </a>
                    <Modal isOpen={modal1} toggle={() => setModal1(false)} style={{
                      maxWidth: "900px"
                    }}>
                      <div className="modal-header justify-content-center">
                        <button
                          className="close"
                          type="button"
                          onClick={() => setModal1(false)}
                        >
                          <i className="now-ui-icons ui-1_simple-remove"></i>
                        </button>
                        <h4 className="title title-up"></h4>
                      </div>
                      <ModalBody>
                        <img
                          alt="..."
                          src={require("../../assets/img/dogs/litters/vh2022/announcement.jpg")}
                        ></img>
                      </ModalBody>
                    </Modal>
                  </Col>
                  <Col md="7">
                    <h2 className="title">Hop Sing x Venus 2022</h2>
                    <p>Venus and Hop Sing are two dogs with great temperaments. Both dogs are friendly with strangers, get along well in large groups of dogs, and have no environmental sensitivites. Venus had a phenomenal flyball debut posting all sub 4.0 times and getting a personal best of 3.805. She comes from mostly herding lines and has some well known dogs in her pedigree such as Hutchinson's Sweep and Rafter W Flank who had many dock diving accomplishments. Hop Sing has multiple relatives who have made world teams in agility including his littermate Chibi who is on the 2022 European Open team. I expect puppies that can be successful in both novice and experienced homes, and have the physical ability and drive to pursue multiple sports.</p>
                    <p>Venus and HopSing are clear of all testable diseases for their breeds. Venus has been cleared of hip and elbow dysplasia and shoulder OCD.</p>
                    <p>For more information about Venus, view her individual page. For more information on Hop Sing, contact Jeanette Hutchinson or reach out to me if you would like to learn about this cross.</p> 
                  </Col>
                </Row>
                <Row className="pt-5">
                  <Col md="1"></Col>
                  <Col md="3">
                    <a className="dogs-link">
                      <img
                      alt="..."
                      className="img-fluid img-raised"
                      src={require("../../assets/img/dogs/profile-hop-sing.jpg")}
                      ></img>
                      <div className="dogs-name"><span>Hop Sing</span></div>
                    </a>
                    <a href="/dogs/venus" className="dogs-link">
                      <img
                      alt="..."
                      className="img-fluid pt-5"
                      src={require("../../assets/img/dogs/profile-venus.jpg")}
                    ></img>
                      <div className="dogs-name"><span>Venus</span></div>
                    </a>
                  </Col>
                  <Col md="7" className="pt-5">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={require("../../assets/img/dogs/litters/vh2022/pedigree.jpg")}
                    ></img>
                    <a href="https://dogs.pedigreeonline.com/baybriar-hop-sing-IWoq2g8d/pedigree?hypomating=rafter-w-venus-h6IMYZUB" target="_blank">View pedigree</a>
                  </Col>
                </Row>  
              </div>
            </Container>
            <Container>
              <h2 className="title text-center pt-5">Venus Videos</h2>
              <div className="team">
                <Row>
                  <Col md="6">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/PEZiEg6K7_U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                  </Col>
                  <Col md="6">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/Gub1xyUDvp0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                  </Col>
                </Row>
              </div>  

              <h2 className="title text-center pt-5">Hop Sing's Littermate Chibi</h2>
              <div className="team">
                <Row>
                  <Col md="6">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/D9jV1TERiNU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                  </Col>
                  <Col md="6">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/jreh9nC7dC4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                  </Col>
                </Row>
              </div>  
            </Container>
          </div>
          <div className="section section-dog text-center">
            <div
              className="image-fullw section-sep-image"
              style={{
                backgroundImage: "url(" + require("../../assets/img/dogs/litters/vh2022/header3.jpg") + ")"
              }}
            ><div className="wrapper">

            </div>
            </div>
          </div>
          <div className="wrapper">
            <Container>
              <h2 className="title text-center">The Litter</h2>
                <div className="team text-center">
                  <Row>
                    <Col md="3">
                    </Col>
                    <Col md="6">
                      <p>Venus had an easy labor and gave birth to 8 puppies on July 16th, 2022. The litter theme was Sailor Scouts after Venus' namesake character.</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <h4 className="title">Cinco</h4>
                      <p className="registered-name text-primary">Endgame's High Five</p>
                      <PuppyCarousel items={PlutoImages} />
                      <div className="mt-3 blockquote text-left">
                        <div><span class="badge badge-neutral">Litter name:</span> Pluto</div>
                        <div><span class="badge badge-neutral">Stats:</span> Male, 16" / 24lbs</div>
                        <div><span class="badge badge-neutral">Owner:</span> Donna Jo Staivecki</div>
                        <div><span class="badge badge-neutral">Location:</span> PA</div>
                        <div><span class="badge badge-neutral">BAER:</span> Normal</div>
                      </div>
                    </Col>
                    <Col md="4">
                      <h4 className="title">Nimona</h4>
                      <p className="registered-name text-primary">Endgame's I'm A Shark</p>
                      <PuppyCarousel items={MercuryImages} />
                      <div className="mt-3 blockquote text-left">
                        <div><span class="badge badge-neutral">Litter name:</span> Mercury</div>
                        <div><span class="badge badge-neutral">Stats:</span> Female, 15.75" / 20lbs</div>
                        <div><span class="badge badge-neutral">Owner:</span> Adriana Nottestad</div>
                        <div><span class="badge badge-neutral">Location:</span> NC</div>
                        <div><span class="badge badge-neutral">BAER:</span> Normal</div>
                        <div><span class="badge badge-neutral">CAER:</span> Normal</div>
                        <div><span class="badge badge-neutral">Hips:</span> Penhip .36/.41, OFA Hips Good</div>
                        <div><span class="badge badge-neutral">Other:</span> Shoulders & patellas - OFA normal</div>
                      </div>
                    </Col>
                    <Col md="4">
                      <h4 className="title">Vox</h4>
                      <p className="registered-name text-primary">Endgame’s Just a Little FOMO</p>
                      <PuppyCarousel items={SaturnImages} />
                      <div className="mt-3 blockquote text-left">
                        <div><span class="badge badge-neutral">Litter name:</span> Saturn</div>
                        <div><span class="badge badge-neutral">Stats:</span> Male, 15.5" / 22lbs</div>
                        <div><span class="badge badge-neutral">Owner:</span> Alexa Capelletti</div>
                        <div><span class="badge badge-neutral">Location:</span> NC</div>
                        <div><span class="badge badge-neutral">BAER:</span> Normal</div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <h4 className="title">Moxie</h4>
                      <p className="registered-name text-primary">Endgame's Hurricane Moxie</p>
                      <PuppyCarousel items={JupiterImages} />
                      <div className="mt-3 blockquote text-left">
                        <div><span class="badge badge-neutral">Litter name:</span> Jupiter</div>
                        <div><span class="badge badge-neutral">Stats:</span> Female, 16.25" / 23lbs</div>
                        <div><span class="badge badge-neutral">Owner:</span> Madison Carriere</div>
                        <div><span class="badge badge-neutral">Location:</span> WA</div>
                        <div><span class="badge badge-neutral">BAER:</span> Normal</div>
                      </div>
                    </Col>
                    <Col md="4">
                      <h4 className="title">Jyn Erso</h4>
                      <p className="registered-name text-primary">Endgame's Rebellion Built on Hope</p>
                      <PuppyCarousel items={NeptuneImages} />
                      <div className="mt-3 blockquote text-left">
                        <div><span class="badge badge-neutral">Litter name:</span> Neptune</div>
                        <div><span class="badge badge-neutral">Stats:</span> Female, 14.5" / 17lbs</div>
                        <div><span class="badge badge-neutral">Owner:</span> Alex Nolte</div>
                        <div><span class="badge badge-neutral">Location:</span> SC</div>
                        <div><span class="badge badge-neutral">BAER:</span> Normal</div>
                      </div>
                    </Col>
                    <Col md="4">
                      <h4 className="title">Mars</h4>
                      <p className="registered-name text-primary">Endgame's Out of This World</p>
                      <PuppyCarousel items={MarsImages} />
                      <div className="mt-3 blockquote text-left">
                        <div><span class="badge badge-neutral">Litter name:</span> Mars</div>
                        <div><span class="badge badge-neutral">Stats:</span> Male, 16" / 24lbs</div>
                        <div><span class="badge badge-neutral">Owner:</span> Jeanette Hutchinson</div>
                        <div><span class="badge badge-neutral">Location:</span> MD</div>
                        <div><span class="badge badge-neutral">BAER:</span> Normal</div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <h4 className="title">Boone</h4>
                      <p className="registered-name text-primary">Endgame's Boone</p>
                      <PuppyCarousel items={MoonImages} />
                      <div className="mt-3 blockquote text-left">
                        <div><span class="badge badge-neutral">Litter name:</span> Moon</div>
                        <div><span class="badge badge-neutral">Stats:</span> Male, 15.75" / 21lbs</div>
                        <div><span class="badge badge-neutral">Owner:</span> Bob & Patte Carbaugh</div>
                        <div><span class="badge badge-neutral">Location:</span> TN</div>
                        <div><span class="badge badge-neutral">BAER:</span> Normal</div>
                      </div>
                    </Col>
                    <Col md="4">
                      <h4 className="title">Chibiusa</h4>
                      <p className="registered-name text-primary">Endgame's Ready for Action</p>
                      <PuppyCarousel items={ChibiusaImages} />
                      <div className="mt-3 blockquote text-left">
                        <div><span class="badge badge-neutral">Litter name:</span> Chibiusa</div>
                        <div><span class="badge badge-neutral">Stats:</span> Female, 15" / 19lbs</div>
                        <div><span class="badge badge-neutral">Owner:</span> Kama Rueschenberg</div>
                        <div><span class="badge badge-neutral">Location:</span> NC</div>
                        <div><span class="badge badge-neutral">BAER:</span> Normal</div>
                      </div>
                    </Col>
                    <Col md="4">
                      
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
            <div className="section section-dog text-center">
              <div
                className="image-fullw section-sep-image"
                style={{
                  backgroundImage: "url(" + require("../../assets/img/dogs/litters/vh2022/header4.jpg") + ")"
                }}
                ><div className="wrapper">

                </div>
              </div>
            </div>
            <div className="wrapper">
              <Container>
                <div className="team pb-5">
                  <h2 className="title text-center">Litter Updates</h2>
                  <Row>
                    <Col md="6">
                      <h4 className="title text-primary">Flyball</h4>
                      <p>
                        Nimona, Vox, Jyn, and  Boone are competing in flyball. Nimona, Vox, and Jyn play on That's So Fetch. Boone plays on Big Orange Blast. Boone and his team ran in finals his first Canam/tournament.
                      </p>
                      <p>
                        Personal bests:<br />
                        Nimona: 3.800 (start)<br />
                        Vox: 3.867 (start)<br />
                        Boone: 4.040 (start)<br />
                        Jyn: 3.940 (pack)
                      </p>
                    </Col>
                    <Col md="6" className="pb-5 pt-5 pl-5">
                      <img
                          alt="..."
                          className="img-fluid img-raised"
                          src={require("../../assets/img/dogs/litters/vh2022/flyball.jpg")}
                        ></img>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="pb-5 pt-5 pr-5">
                      <img
                          alt="..."
                          className="img-fluid img-raised"
                          src={require("../../assets/img/dogs/litters/vh2022/agility.jpg")}
                        ></img>
                    </Col>
                    <Col md="6">
                      <div>
                        <h4 className="title text-primary">Agility</h4>
                        <p>
                          Chibi, Moxie, and Cinco are currently training competitively in agility. Nimona and Jyn will play casually.
                        </p>
                        <p>
                          Videos of their agility runs can be found on the FB page.
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <div>
                        <h4 className="title text-primary">Disc</h4>
                        <p>
                          Nimona, Vox, and Jyn compete in disc.
                        </p>  
                        <p>
                          Nimona is in the expert division in K9 Toss and Fetch with a personal best of 55 (max score). At 10 months, she was ranked #109 out of 3,245 teams in the Spring 2023 season. She was #1 for the summer 2024 90/5 season. Her current UpDog personal bests are 57 in Throw n Go and 175 in Frizgility. She was 2nd in extreme distance at the 2024 Skyhoundz worlds with a 191ft catch.
                        </p>
                        <p>
                          Vox plays in UpDog. His personal best in Throw n Go is 55.
                        </p>
                      </div>
                    </Col>
                    <Col md="6" className="pb-5 pt-5 pl-5">
                      <img
                          alt="..."
                          className="img-fluid img-raised"
                          src={require("../../assets/img/dogs/litters/vh2022/disc.jpg")}
                        ></img>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="pb-5 pt-5 pr-5">
                      <img
                          alt="..."
                          className="img-fluid img-raised"
                          src={require("../../assets/img/dogs/litters/vh2022/dockdiving.jpg")}
                        ></img>
                    </Col>
                    <Col md="6">
                      <div>
                        <h4 className="title text-primary">Dock Diving</h4>
                        <p>
                          Nimona and Vox compete in dock diving. Nim's personal best is 26ft 5in, and 17ft in air retrieve. She was ranked the 2023 #1 club lap dog in the USA in Dock Dogs. For the 2024 NADD season, she was #1 lap dog in Air Retrieve and #2 in distance. 
                        </p>
                        <p>
                          Vox has a pb of 21'6 in distance from his first event.
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <h3 className="title text-center">Photos</h3>
                  <div
                    className="image-fullw section-gallery-bg"style={{
                      padding: "0 0 50px 0"
                    }}>
                    <DogGallery images={images} />
                  </div>
                  <h3 className="title text-center">Group Videos</h3>
                  <Row>
                    <Col md="6" className="text-center">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/ucBywyamP3U?si=X5CdoOqXgNIm7Hob" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe>
                      <p>2 Years - Moxie, Nimona, Boone, Chibiusa, Cinco, Jyn, Vox</p>
                    </Col>
                    <Col md="6" className="text-center">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/t-n6fS8_NN8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe>
                      <p>12 months - Nimona, Jyn, Vox, Boone, Moxie, Chibi, Cinco</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="text-center">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/IebmrMcSbf0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe>
                      <p>6 months - Moxie, Chibiusa, Cinco, Jyn, Vox</p>
                    </Col>
                  </Row>
                </div>  
              </Container>
            </div>   
          <DefaultFooter />
        </div>
      </>
  );
}

export default VH2022Page;
